import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import { colors, mediaQueries, weights, container } from "../../styles"

const Menu = ({ menuOpen, toggleOpen }) => {
  const textFadeIn = css`
    position: relative;
    opacity: ${menuOpen ? "1" : "0"};
    transform: translateY(${menuOpen ? "0" : "50%"});
    transition-property: color, transform, opacity;
    transition-timing-function: ease-in-out;
    transition-duration: 1s;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: ${colors.brBlue};
      transition: inherit;
      height: ${menuOpen ? "0" : "100%"};
    }
  `

  const mobileDelay1 = css`
    transition-delay: 0s, 0.1s, 0.1s;
  `

  const mobileDelay2 = css`
    transition-delay: 0s, 0.2s, 0.2s;
  `

  const mobileDelay3 = css`
    transition-delay: 0s, 0.3s, 0.3s;
  `

  const mobileDelay4 = css`
    transition-delay: 0s, 0.4s, 0.4s;
  `

  const mobileDelay5 = css`
    transition-delay: 0s, 0.5s, 0.5s;
  `

  const mobileDelay6 = css`
    transition-delay: 0s, 0.6s, 0.6s;
  `

  const mobileDelay7 = css`
    transition-delay: 0s, 0.7s, 0.7s;
  `

  const mobileDelay8 = css`
    transition-delay: 0s, 0.8s, 0.8s;
  `

  const mobileDelay9 = css`
    transition-delay: 0s, 0.9s, 0.9s;
  `

  const mobileDelay10 = css`
    transition-delay: 0s, 1s, 1s;
  `

  const mobileDelay11 = css`
    transition-delay: 0s, 1.1s, 1.1s;
  `

  const mobileDelay12 = css`
    transition-delay: 0s, 1.2s, 1.2s;
  `

  const mobileDelay13 = css`
      transition-delay: 0s, 1.3s, 1.3s;
  `

  const mobileDelay14 = css`
      transition-delay: 0s, 1.4s, 1.4s;
  `

  const desktopDelay1 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.2s, 0.2s;
    }
  `

  const desktopDelay2 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.4s, 0.4s;
    }
  `

  const desktopDelay3 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.6s, 0.6s;
    }
  `

  const desktopDelay4 = css`
    ${mediaQueries.phoneLarge} {
      transition-delay: 0s, 0.8s, 0.8s;
    }
  `
  const desktopDelay5 = css`
      ${mediaQueries.phoneLarge} {
          transition-delay: 0s, 0.9s, 0.9s;
      }
  `
  const desktopDelay6 = css`
      ${mediaQueries.phoneLarge} {
          transition-delay: 0s, 1s, 1s;
      }
  `

  const linkBaseStyles = css`
    display: block;
    color: ${colors.yellow};
    font-weight: ${weights.regular};
    border-bottom: none;
    letter-spacing: -0.2px;

    &:hover,
    &:focus {
      color: ${colors.white};
    }
    ${mediaQueries.phoneLarge} {
      font-weight: ${weights.medium};
    }
  `

  const linkPrimaryStyle = css`
    font-size: 25px;
    line-height: 45px;
    @media (max-width: 350px) {
      font-size: 18px;
      line-height: 37px;
    }
    ${mediaQueries.phoneLarge} {
      font-size: 36px;
      line-height: 70px;
    }
    ${mediaQueries.desktop} {
      font-size: 44px;
      line-height: 81px;
    }
  `

  const sectionStyle = css`
    ${mediaQueries.phoneLarge} {
      padding: 0;
    }
  `

  const sectionPrimaryStyle = css`
    h5 {
      display: none;

      ${mediaQueries.phoneLarge} {
        display: block;
      }
    }
  `

  const sectionHeaderStyle = css`
    font-weight: ${weights.light};
    color: ${colors.white};
    letter-spacing: -0.1px;
    line-height: 15px;
    font-size: 15px;
    margin-bottom: 10px;

    ${mediaQueries.phoneLarge} {
      margin-bottom: 26px;
      line-height: 81px;
      font-size: 21px;
    }
  `

  return (
    <>
      <nav
        css={css`
          position: fixed;
          width: 100vw;
          display: flex;
          align-items: center;
          background-color: ${colors.brBlue};
          transition: 0.3s ease all;
          overflow: hidden;
          z-index: 3;
          top: ${menuOpen ? "0" : "100vh"};
          flex-direction: column;
          justify-content: center;
          height: ${menuOpen ? "100vh" : "0"};
          max-height: ${menuOpen ? "100vh" : "0"};
          padding: 0;
          ${mediaQueries.phoneLarge} {
            flex-direction: row;
            height: ${menuOpen ? "100vh" : "0"};
          }
        `}
      >
        <div
          css={[
            container.max,
            css`
              padding-top: 80px;
              padding-bottom: 60px;
              padding-left: 30px;
              padding-right: 30px;
              ${mediaQueries.phoneLarge} {
                display: flex;
                justify-content: space-around;
                padding: 0;
              }
            `,
          ]}
        >
          <section
            css={[
              sectionStyle,
              sectionPrimaryStyle,
              css`
                justify-self: center;
                text-align: center;
                //add border bottom to the section
                padding-bottom: 10px;

                ${mediaQueries.phoneLarge} {
                  padding-bottom: 0;

                  justify-self: flex-start;
                  text-align: left;
                }
              `,
            ]}
          >
            <h5
              css={[
                sectionHeaderStyle,
                textFadeIn,
                desktopDelay1,
                css`
                  text-align: left;
                `,
              ]}
            >
              What We Do
            </h5>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay1,
                desktopDelay1,
              ]}
              onClick={() => toggleOpen()}
              to="/news/"
            >
              News
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay2,
                desktopDelay2,
              ]}
              onClick={() => toggleOpen()}
              to="/projects/"
            >
              Projects
            </Link>
            <Link
              onClick={() => toggleOpen()}
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay3,
                desktopDelay3,
              ]}
              to="/international-tours/"
            >
              Recent Tours
            </Link>
            <Link
              onClick={() => toggleOpen()}
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay4,
                desktopDelay4,
              ]}
              to="/sponsorships/"
            >
              Sponsorships
            </Link>
          </section>

          <section
            css={[
              sectionStyle,
              sectionPrimaryStyle,
              css`
                justify-self: center;
                text-align: center;
                padding: 10px 0;
                ${mediaQueries.phoneLarge} {
                  padding: 0;
                }
              `,
            ]}
          >
            <h5
              css={[
                sectionHeaderStyle,
                textFadeIn,
                desktopDelay1,
                css`
                  text-align: center;
                `,
              ]}
            >
              Artist Management
            </h5>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay5,
                desktopDelay1,
              ]}
              onClick={() => toggleOpen()}
              to="/artists/"
            >
              All Artists
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay6,
                desktopDelay2,
              ]}
              onClick={() => toggleOpen()}
              to="/soloists/"
            >
              Soloists
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay7,
                desktopDelay3,
              ]}
              onClick={() => toggleOpen()}
              to="/composers/"
            >
              Composers
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay8,
                desktopDelay4,
              ]}
              onClick={() => toggleOpen()}
              to="/conductors/"
            >
              Conductors
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay9,
                desktopDelay5,
              ]}
              onClick={() => toggleOpen()}
              to="/orchestras/"
            >
              Orchestras
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay10,
                desktopDelay6,
              ]}
              onClick={() => toggleOpen()}
              to="/ensembles/"
            >
              Chamber Music
            </Link>
          </section>

          <section
            css={[
              sectionStyle,
              sectionPrimaryStyle,
              css`
                justify-self: center;
                text-align: center;
                padding-top: 10px;
                ${mediaQueries.phoneLarge} {
                  padding-top: 0;
                  justify-self: flex-end;
                  text-align: right;
                }
              `,
            ]}
          >
            <h5
              css={[
                sectionHeaderStyle,
                textFadeIn,
                mobileDelay10,
                desktopDelay1,
                css`
                  text-align: right;
                `,
              ]}
            >
              Who We Are
            </h5>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay11,
                desktopDelay1,
              ]}
              onClick={() => toggleOpen()}
              to="/about/"
            >
              About
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay12,
                desktopDelay2,
              ]}
              onClick={() => toggleOpen()}
              to="/team/"
            >
              Team
            </Link>
            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay13,
                desktopDelay3,
              ]}
              onClick={() => toggleOpen()}
              to="/work-with-us/"
            >
              Careers
            </Link>

            <Link
              css={[
                linkPrimaryStyle,
                textFadeIn,
                linkBaseStyles,
                mobileDelay14,
                desktopDelay4,
              ]}
              onClick={() => toggleOpen()}
              to="/contact/"
            >
              Contact
            </Link>
          </section>
        </div>
      </nav>
    </>
  )
}

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}

export default Menu
